"use strict";

var $ = require("jquery");

function tabReplaceHash(element) {
    let url = location.href.replace(/\/$/, "");
    let newUrl;
    const hash = element.attr("href");
    if (hash == "#home") {
        newUrl = url.split("#")[0];
    } else {
        newUrl = url.split("#")[0] + hash;
    }
    history.replaceState(null, null, newUrl);
}

/* --- Tabs - Deeplinking --- */

function tabDeeplink() {

    let url = location.href.replace(/\/$/, "");
    let tabGroup = $(".tab-deeplinking");

    if (location.hash && tabGroup.length) {
        let hash = url.split("#");
        hash = hash[1];
        if (hash == 'comments' || hash.startsWith("comment")) {
            hash = 'reviews';
        }

        var triggerEl = document.querySelector('a[data-bs-target="#' + hash + '"]');
        bootstrap.Tab.getOrCreateInstance(triggerEl).show();

        url = location.href.replace(/\/#/, "#");
        history.replaceState(null, null, url);
        setTimeout(() => {
            $(window).scrollTop(tabGroup.offset().top - 200);
        }, 400);
    }

    $('a[data-toggle="tab"]').on("click", function () {
        tabReplaceHash($(this));
    });
}

$(document).ready(function () {
    tabDeeplink();

    $('.woocommerce-review-link').on("click", function () {
        tabReplaceHash($(this));
        tabDeeplink();
    });

    $('.woocommerce-product-details__short-description .link').on("click", function () {
        tabReplaceHash($(this));
        tabDeeplink();
    });

    $('#comments .page-numbers').on("click", function () {
        tabReplaceHash($(this));
        tabDeeplink();
    });
});