"use strict";

var $ = require("jquery");

$(document).ready(function () {
    let scrollPosition = 0;
    $(window).on('scroll', function () {
        scrollPosition = $(this).scrollTop();
        if (scrollPosition >= 120) {
            $('body').addClass('header-passed');
        } else {
            $('body').removeClass('header-passed');
        }
        if (scrollPosition >= 130) {
            $('body').addClass('header-sticky');
        } else {
            $('body').removeClass('header-sticky');
        }
    });
});