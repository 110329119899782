"use strict";

var $ = require("jquery");
const Cookies = require('js-cookie');

$(document).ready(function () {

    $(".appointment-alert .alert-close").click(function (e) {
        Cookies.set('appointment-alert', 'hide', { expires: 30 });

        if (Cookies.get('appointment-alert') == 'hide') {

            $('.appointment-alert').css('display', 'none');
            $('.appointment-alert').css('height', 0);
        }
    });

    /* --- Sticky alert --- */

    let prevScrollpos = window.pageYOffset;
    document.getElementById("appointment-alert").style.bottom = "0";
    window.onscroll = function () {
        let currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos || prevScrollpos === currentScrollPos) {
            document.getElementById("appointment-alert").style.bottom = "0";
        } else {
            document.getElementById("appointment-alert").style.bottom = "-200px";
        }
        prevScrollpos = currentScrollPos;
    }

    /* --- Appointment alert mobile --- */

    if ($(window).width() < 1200) {
        $(".appointment-alert-mobile").addClass('show');

        setTimeout(
            function () {
                $(".appointment-alert-mobile").removeClass('show');
            }, 5000);
    }

    // $(".appointment-btn").hover(function () {
    //     $(".appointment-alert-mobile").addClass('show');
    // }, function () {
    //     $(".appointment-alert-mobile").removeClass('show');
    // });


});