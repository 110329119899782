"use strict";

var $ = require("jquery");

$(document).ready(function () {

    if ($('.short-description-read-more').length > 0) {
        $('.short-description-read-more').on('click', function () {
            $('.woocommerce-product-details__short-description').toggleClass('more');
            $(this).toggleClass('less');
        });
    }
});

window.addEventListener('load', function () {
    if ($('.short-description-wrapper').length > 0) {
        $('.short-description-wrapper').each(function () {
            var scrollHeight = this.getElementsByClassName('woocommerce-product-details__short-description')[0].scrollHeight;
            var clientHeight = this.getElementsByClassName('woocommerce-product-details__short-description')[0].clientHeight;
            console.log(scrollHeight);
            var thisElem = this;
            if (scrollHeight > clientHeight) {
                $(this).children('.short-description-read-more').show();
            } else {
                $(thisElem).find('.short-description-read-more').hide();
            }
        })
    }
});