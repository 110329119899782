"use strict";

var $ = require("jquery");
var Flickity = require('flickity');
import { Fancybox } from "@fancyapps/ui";
// import { sliderGroupCells } from './_sliderGroupCells.js';

function addToCart(form, type) {

    // Remove existing notices
    $('.woocommerce-message').remove();
    $('.woocommerce-error').remove();
    $('.woocommerce-notice').remove();

    // var $thisbutton = $(this),
    //     $form = $thisbutton.closest('form.cart'),
    //     id = $thisbutton.val(),
    //     product_qty = $form.find('input[name=quantity]').val() || 1,
    //     product_id = $form.find('input[name=product_id]').val() || id,
    //     variation_id = $form.find('input[name=variation_id]').val() || 0;

    // form.block({ message: null, overlayCSS: { background: '#fff', opacity: 0.6 } });

    var formData = new FormData(form[0]);

    formData.append('add-to-cart', form.find('[name=add-to-cart]').val());
    formData.append('type', type);

    // for (var pair of formData.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    // }

    // Ajax action.
    $.ajax({
        url: wc_add_to_cart_params.wc_ajax_url.toString().replace('%%endpoint%%', 'ace_add_to_cart'),
        data: formData,
        type: 'POST',
        processData: false,
        contentType: false,
        complete: function (response) {
            response = response.responseJSON;

            if (!response) {
                return;
            }

            if (response.error && response.product_url) {
                window.location = response.product_url;
                return;
            }

            // Redirect to cart option
            if (wc_add_to_cart_params.cart_redirect_after_add === 'yes') {
                window.location = wc_add_to_cart_params.cart_url;
                return;
            }

            var error = false;

            for (let i = 0; i < response.fragments.notices_types.length; i++) {
                if (response.fragments.notices_types[i] == 'error') {
                    error = true;
                }
            };
            console.log(error);

            if (!error && response.modal_fragments) {

                var fancyboxClass = 'fancybox-add-to-cart';
                if (response.grouped_product) {
                    fancyboxClass = 'fancybox-grouped-add-to-cart';
                }

                Fancybox.show(
                    [
                        {
                            src: '<div class="' + fancyboxClass + '">' + response.modal_fragments + '</div>',
                            type: "html",
                        }
                    ],
                    {
                        mainClass: fancyboxClass
                    }
                );

                // var $carousel = $('.modal-cart .cross-sells ul.products');
                // var hasMultipleCells = $carousel.find('li.product').length > sliderGroupCells();

                // if (hasMultipleCells) {
                //     const flkty = new Flickity('.modal-cart .cross-sells ul.products', {
                //         cellAlign: 'left',
                //         contain: true,
                //         groupCells: sliderGroupCells(),
                //         pageDots: false
                //     });
                // }
            }

            if (!error) {

                var $thisbutton = form.find('.single_add_to_cart_button'); //

                // Trigger event so themes can refresh other areas.
                $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);
            }

            // Add new notices
            form.after(response.fragments.notices_html)

            // form.unblock();
        }
    });
};

export { addToCart };