"use strict";

var $ = require("jquery");
import { dropdownClose } from './_dropdownClose.js';

$(document).ready(function () {

    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            dropdownClose();
        }
    });

    $('.overlay-full').on('click', function (e) {
        dropdownClose();
    });

    $('header').on('click', function (e) {
        dropdownClose();
    });

    $('.dropdown-close').on('click', function (e) {
        dropdownClose();
    });
});
