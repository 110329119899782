"use strict";

var $ = require("jquery");
import { addToCart } from './_addToCart.js';

$(document).ready(function () {

    $('form.cart').on('submit', function (e) {
        e.preventDefault();
        addToCart($(this));
    });
});