"use strict";

var $ = require("jquery");

function sliderGroupCells() {

    let groupCells = 4;

    if ($(window).width() < 1200) {
        groupCells = 3;
    }

    if ($(window).width() < 992) {
        groupCells = 2;
    }

    if ($(window).width() < 576) {
        groupCells = 1;
    }

    return groupCells;
}

export { sliderGroupCells };