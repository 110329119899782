"use strict";

var $ = require("jquery");
var Flickity = require('flickity');

$(document).ready(function () {
    var sliders = document.querySelectorAll('.is-style-product-slider');
    for (var i = 0; i < sliders.length; i++) {
        let innerDiv = sliders[i].querySelector('.wc-block-grid__products');
        let flkty = new Flickity(innerDiv, {
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            prevNextButtons: true
        });
    }

    var overflowHiddenSliders = document.querySelectorAll('.is-style-product-slider-overflow-hidden');
    for (var i = 0; i < overflowHiddenSliders.length; i++) {
        let innerDiv = overflowHiddenSliders[i].querySelector('.wc-block-grid__products');
        let flkty = new Flickity(innerDiv, {
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            prevNextButtons: true
        });
    }
});