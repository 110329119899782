"use strict";

var $ = require("jquery");
var Flickity = require('flickity');
import { sliderGroupCells } from './_sliderGroupCells.js';

$(document).ready(function () {

    setTimeout(
        function () {
            let carousel = $('.related.products ul.products');
            let hasMultipleCells = carousel.find('li.product').length > sliderGroupCells();

            if (hasMultipleCells) {
                const flkty = new Flickity('.related.products ul.products', {
                    cellAlign: 'left',
                    contain: true,
                    // groupCells: sliderGroupCells(),
                    pageDots: false,
                });
            }
        }, 10);
});