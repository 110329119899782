"use strict";

var $ = require("jquery");
import { dropdownToggle } from './_dropdownToggle.js';

$(document).ready(function ($) {

    const button = $('.nav-side-btn');
    button.on('click', function () {
        dropdownToggle('nav-side');
    });
    const closeButton = $('.nav-side .btn-close');
    closeButton.on('click', function () {
        dropdownToggle('nav-side');
    });
    const submenuButton = $('.nav-side .menu-item-has-children');
    // submenuButton.find("a").attr("href", "#")
    submenuButton.on('click', function (event) {
        // event.preventDefault();

        $(this).toggleClass('open');
        // if (!$(this).hasClass('open')) {
        //     $(this).addClass('open');
        // } else {
        //     $(this).addClass('open');

        // }
    });
});