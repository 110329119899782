"use strict";

var $ = require("jquery");

$(document).ready(function () {

    $('.summary').on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - 100
        }, 200);
    });
});