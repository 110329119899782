"use strict";

var $ = require("jquery");
var Flickity = require('flickity');
import { sliderGroupCells } from './_sliderGroupCells.js';

$(document).ready(function () {
    $(".wp-block-woocommerce-products ul.products").each(function (index) {
        let carousel = $(this);
        let hasMultipleCells = carousel.find('li.product').length > sliderGroupCells();

        if (hasMultipleCells) {
            const flkty = new Flickity(this, {
                cellAlign: 'left',
                contain: true,
                // groupCells: sliderGroupCells(),
                pageDots: false,
            });
        }
    });
});