"use strict";

var $ = require("jquery");

$(document).ready(function () {

    if ($(window).width() < 768) {
        $('select.orderby').attr('dir', 'rtl');
    }
    window.addEventListener('resize', () => {
        if ($(window).width() < 768) {
            $('select.orderby').attr('dir', 'rtl');
        } else {
            $('select.orderby').attr('dir', 'ltr');
        }
    });
});