"use strict";

var $ = require("jquery");

$(document).on('facetwp-refresh', function () {
    if (FWP.soft_refresh == true) {
        FWP.enable_scroll = true;
    } else {
        FWP.enable_scroll = false;
    }
});
$(document).on('facetwp-loaded', function () {
    if (FWP.enable_scroll == true) {
        $('html, body').animate({ scrollTop: 500 }, 500);
    }
});

// $(document).ready(function () {

//     const productFilterWidget = $('.widget');

//     if ($(window).width() >= 992) {
//         productFilterWidget.addClass('show');
//     }

//     window.addEventListener('resize', () => {
//         if ($(window).width() < 992) {
//             productFilterWidget.removeClass('show');
//         } else {
//             productFilterWidget.addClass('show');
//         }
//     });

//     $('.product-filters .filter-title').on('click', function () {
//         let widget = $(this).closest('.widget');
//         if (widget.hasClass('show')) {
//             widget.removeClass('show');
//         } else {
//             widget.addClass('show');
//         }
//     });
// });

/* --- Hide empty product filters --- */

// function hideEmptyFilters() {
//     $.each(FWP.settings.num_choices, function (key, val) {
//         var $parent = $('.facetwp-facet-' + key).closest('.widget');
//         (0 === val) ? $parent.hide() : $parent.show();
//     });
// };

// $(document).on('facetwp-loaded', function () {
//     hideEmptyFilters();
// });