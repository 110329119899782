"use strict";

var $ = require("jquery");
var Flickity = require('flickity');
import { sliderGroupCells } from './_sliderGroupCells.js';

$(document).ready(function () {
    let carousel = $('.cross-sells ul.products');
    let hasMultipleCells = carousel.find('li.product').length > sliderGroupCells();

    if (hasMultipleCells) {
        const flkty = new Flickity('.cross-sells ul.products', {
            cellAlign: 'left',
            contain: true,
            // groupCells: sliderGroupCells(),
            pageDots: false
        });
    }
});