"use strict";

var $ = require("jquery");
import { dropdownToggle } from './_dropdownToggle.js';

$(document).ready(function () {

    // var input = $('#search-form-input');
    var searchFormInput = $('#search-form-input');
    // var searchFormInputMobile = $('#search-form-input-mobile');
    var searchFormButton = $('.search-form-button');
    var resultsFound = $('.search-form-dropdown .results-found');
    var noResults = $('.search-form-dropdown .no-results');

    function getSearchResults(string) {

        if (!string)
            return;

        let loadingEl = $('.search-form-dropdown .loading');

        jQuery.ajax({
            type: 'post',
            dataType: 'json',
            url: vars.ajaxurl,
            data: {
                action: 'juist_search',
                string: string
            },
            beforeSend: function () {
                loadingEl.show();
            },
            success: function (response) {
                if (string.length > 2 && response.results) {
                    resultsFound.html(response.content);
                    noResults.hide();
                }
                else {
                    resultsFound.html('');
                    noResults.show();
                }

                setTimeout(function () {
                    loadingEl.hide();
                }, 500);
            }
        });
    }

    searchFormButton.on('click', function () {
        dropdownToggle('search-results');
        setTimeout(function () { $('#search-form-input').focus(); }, 500);
    });

    var timeoutID;
    searchFormInput.keyup(function (e) {
        if (e.which == 13) {
            return;
        }
        clearTimeout(timeoutID);
        let string = String(this.value);
        timeoutID = setTimeout(function () {
            getSearchResults(string);
        }, 750);
    });

    searchFormInput.click(function () {
        let string = String(this.value);
        getSearchResults(string);
        // dropdownToggle('search-results');
    });

    searchFormInput.keyup(function () {
        let string = String(this.value);
        getSearchResults(string);
    });


    // searchFormInput.keyup(function () {
    //     searchFormInput.val($(this).val());
    // });
});