"use strict";

var $ = require("jquery");

function overlayToggle() {
    if (overlay === 1) {
        $('body').addClass('show-overlay');
        return;
    }
    $('body').removeClass('show-overlay');
}

export { overlayToggle };