"use strict";

var $ = require("jquery");
const Cookies = require('js-cookie');
// import { dropdownPositionTop } from './_dropdownPositionTop.js';
// import { dropdownHeight } from './_dropdownHeight.js';

$(document).ready(function () {

    $(".custom-alert .alert-close").click(function (e) {
        Cookies.set('alert', 'hide', { expires: 30 });

        if (Cookies.get('alert') == 'hide') {

            $('.custom-alert').css('display', 'none');
            $('.custom-alert').css('height', 0);
        }
    });
});