"use strict";

var $ = require("jquery");

$(document).ajaxComplete(function () {
    if ($('.fancybox-quickview.fancybox-is-open').length > 0) {
        var form_variation = $('.variations_form');
        form_variation.wc_variation_form();
        form_variation.trigger('check_variations');
    }
});