"use strict";

var $ = require("jquery");
import { dropdownToggle } from './_dropdownToggle.js';
var Flickity = require('flickity');

$(document).ready(function ($) {

    // Open materials window on button click
    const button = $('.single-product .btn-materials');
    button.on('click', function () {
        dropdownToggle('product-configurator');
    });

    // Open materials window from archive
    if (window.location.hash) {
        let hash = $(location).prop('hash');
        if (hash === '#materials') {
            dropdownToggle('product-configurator');
            history.replaceState(null, document.title, location.pathname + location.search)
        }
    }

    // Close materials window
    const closeButton = $('#product-configurator .btn-back-to-product');
    closeButton.on('click', function () {
        dropdownToggle('product-configurator');
    });

    // Load series when chosen part and raw-material
    $('#product-configurator .raw-material').click(function () {
        $('#product-configurator .raw-material').removeClass('active');
        $(this).addClass('active');
        let brand = $(this).attr('data-brand');
        let rawMaterial = $(this).attr('data-raw-material');
        getSeries(brand, rawMaterial);
        // Responsive - Move to series when raw material is chosen
        if ($(window).width() < 992) {
            $('#product-configurator').addClass('show-series');
        }
    });

    // Responsive - Back to raw materials
    $('#product-configurator .btn-back-to-parts').click(function () {
        $('#product-configurator').removeClass('show-series');
    });

    // Get series and materials
    function getSeries(brand, rawMaterial) {

        if (!brand)
            return;

        let loadingEl = $('#product-configurator .loading');
        let seriesEl = $('#product-configurator .series');

        jQuery.ajax({
            type: 'post',
            dataType: 'json',
            url: vars.ajaxurl,
            data: {
                action: 'juist_get_series',
                brand: brand,
                rawMaterial: rawMaterial
            },
            beforeSend: function () {
                loadingEl.show();
                seriesEl.hide();
                $('#product-configurator .series').empty();
            },
            success: function (response) {
                $('#product-configurator .series').append(response.data);
                seriesEl.fadeIn(200);

                setTimeout(function () {
                    loadingEl.hide();
                }, 500);

                var sliders = document.querySelectorAll('#product-configurator .serie');
                for (var i = 0; i < sliders.length; i++) {
                    let slider = sliders[i].querySelector('.materials');
                    let flkty = new Flickity(slider, {
                        cellAlign: 'left',
                        contain: true,
                        pageDots: false,
                        prevNextButtons: true
                    });
                }
            }
        });
    }

    // Open material detail window
    $(document).on('click', '#product-configurator .material', function () {
        let material = $(this).attr('data-material');
        getMaterial(material);
        dropdownToggle('product-configurator-material');
    });
    // $('body').addClass('show-product-configurator');

    // Close material detail window
    const closeButtonMaterial = $('#product-configurator-material .is-style-back');
    closeButtonMaterial.on('click', function () {
        dropdownToggle('product-configurator-material');
    });

    // Get material
    function getMaterial(material) {

        if (!material)
            return;

        let loadingEl = $('#product-configurator-material .loading');

        jQuery.ajax({
            type: 'post',
            dataType: 'json',
            url: vars.ajaxurl,
            data: {
                action: 'juist_get_material_details',
                material: material
            },
            beforeSend: function () {
                loadingEl.show();
                $('#product-configurator-material .material-data').empty();
            },
            success: function (response) {
                $('#product-configurator-material .material-data').append(response.data);

                setTimeout(function () {
                    loadingEl.hide();
                }, 500);
            }
        });
    }
});