"use strict";

var $ = require("jquery");
var Flickity = require('flickity');
require('flickity-as-nav-for');

function productGallery() {
    const carousel = new Flickity('.flickity-product-gallery', {
        cellAlign: 'left',
        contain: true,
        pageDots: false,
    });
};

// function hasWhiteBackground(imgEl) {

//     var defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
//         canvas = document.createElement('canvas'),
//         context = canvas.getContext && canvas.getContext('2d'),
//         data, width, height

//     if (!context) {
//         return defaultRGB;
//     }

//     height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
//     width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

//     context.drawImage(imgEl, 0, 0);

//     try {
//         data = context.getImageData(0, 0, width, height);
//     } catch (e) {
//         return defaultRGB;
//     }

//     let colors = {};
//     let whiteFound = 0;
//     colors.topLeft = canvas.getContext('2d').getImageData(1, 1, 1, 1).data;
//     colors.topRight = canvas.getContext('2d').getImageData(width - 1, 1, 1, 1).data;
//     colors.bottomRight = canvas.getContext('2d').getImageData(width - 1, height - 1, 1, 1).data;
//     colors.bottomLeft = canvas.getContext('2d').getImageData(1, height - 1, 1, 1).data;

//     for (let index in colors) {
//         let whiteFoundPixel = 0;
//         for (var i = 0; i < colors[index].length; i++) {
//             if (colors[index][i] > 240) {
//                 whiteFoundPixel = whiteFoundPixel + 1;
//             }
//         }
//         if (whiteFoundPixel === 4) {
//             whiteFound = whiteFound + 1;
//         }
//     }
//     if (whiteFound >= 2) {
//         return true;
//     }
//     return false;
// }


$(document).ready(function () {

    if ($('.flickity-product-gallery').length > 0) {
        // var slides = document.getElementsByClassName('product-gallery-item');
        // for (let i = 0; i < slides.length; i++) {
        //     const white = hasWhiteBackground(slides[i]);
        //     if (!white) {
        //         slides[i].style.objectFit = 'cover';
        //     }
        //     slides[i].style.visibility = 'visible';
        // }

        productGallery();
    }
});

window.addEventListener('resize', () => {
    if ($('.flickity-product-gallery').length > 0) {
        productGallery();
    }
});