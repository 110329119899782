"use strict";

var $ = require("jquery");
import { overlayToggle } from './_overlayToggle.js';

function dropdownToggle(toggleEl) {

    const array = [...dropdowns];
    const removeDropdown = array.indexOf(toggleEl);

    if (removeDropdown > -1) {
        array.splice(removeDropdown, 1);
    }

    array.forEach(function (item) {
        $('body').removeClass('show-' + item);
    });

    // if (toggleEl === 'search-results' && $(window).width() >= window.searchInputBreakpoint) {
    //     $('body').addClass('show-' + toggleEl);
    //     window.overlay = 1;
    //     overlayToggle();
    //     return;
    // }

    if ($('body').hasClass('show-' + toggleEl)) {
        $('body').removeClass('show-' + toggleEl);
        window.overlay = 0;
    } else {
        $('body').addClass('show-' + toggleEl);
        window.overlay = 1;
    }

    overlayToggle();
}

export { dropdownToggle };