"use strict";

var $ = require("jquery");
import { dropdownToggle } from './_dropdownToggle.js';

$(document).ready(function ($) {

    // const button = $('.search-form-button');
    // button.on('click', function () {
    //     dropdownToggle('nav-side');
    // });
    const closeButton = $('.search-form-dropdown .btn-close');
    closeButton.on('click', function () {
        dropdownToggle('search-results');
    });
});