"use strict";

/* Vendors */
import { Tab as Tab } from 'bootstrap';

/* Theme variables */
import './js/variables';

/* Theme scripts */
import './js/addToCartProductSingle';
import './js/alertAppointment';
import './js/alertMessage';
// import './js/appointmentForm';
import './js/checkboxCustom';
// import './js/deliveryDateMove';
import './js/dropdownClose';
// import './js/#dropdownHeight';
// import './js/#dropdownPositionRight';
// import './js/#dropdownPositionTop';
import './js/facetWP';
// import './js/filterBtnOnScroll';
// import './js/gridListView';
import './js/headerSticky';
import './js/liveSearch';
import './js/miniCartToggle.js';
// import './js/modalQuickView';
import './js/multiStepCheckout';
// import './js/navCategoriesBackButton';
// import './js/navCategoriesClickNavItem.js';
// import './js/navCategoriesDesktopBase.js';
// import './js/navCategoriesInitSlidePositions';
// import './js/navCategoriesSetSlideWidth.js';
// import './js/navCategoriesSliderToDefault';
// import './js/navCategoriesTrigger.js';
import './js/navSideToggle.js';
import './js/productConfigurator';
// import './js/productDescriptionReadMore';
import './js/productFiltersTrigger.js';
import './js/productGallery';
// import './js/productTabs';
import './js/productSummaryToAnchor';
// import './js/readMore';
// import './js/responsiveIframe';
import './js/searchFormDropdownToggle.js';
// import './js/selectboxResize';
import './js/selectCustom';
import './js/selectCustomOrderBy';
import './js/shortDescriptionReadMore';
import './js/sliderBlockWoocommerceProducts.js';
import './js/sliderBrands.js';
import './js/sliderCrossSells';
import './js/sliderImagesFancybox';
import './js/sliderProducts';
import './js/sliderRelated';
import './js/sliderUpSells';
import './js/tabDeeplink.js';
import './js/tableResponsive';
import './js/quoteFormFancybox';
import './js/popupFancybox';
import './js/variationsForm';
import './js/widgetCollapse';