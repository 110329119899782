"use strict";

var $ = require("jquery");
var Flickity = require('flickity');

$(document).ready(function () {

    if ($('.brands-slider').length > 0) {
        var flkty = new Flickity('.brands-slider', {
            cellAlign: 'left',
            contain: true,
            fullscreen: true,
            pageDots: true,
            prevNextButtons: true,
            autoPlay: true
        });
    }
});