"use strict";

var $ = require("jquery");
import { overlayToggle } from './_overlayToggle.js';

function dropdownClose() {
    let array = [...dropdowns];
    array.forEach(function (item) {
        $('body').removeClass('show-' + item);
        window.overlay = 0;
    });
    overlayToggle();
}

export { dropdownClose };
