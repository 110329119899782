"use strict";

var $ = require("jquery");
var Flickity = require('flickity');
import { sliderGroupCells } from './_sliderGroupCells.js';

$(document).ready(function () {

    let carousel = $('.upsells.products ul.products');
    let hasMultipleCells = carousel.find('li.product').length > sliderGroupCells();

    if (hasMultipleCells) {
        const flkty = new Flickity('.upsells.products ul.products', {
            cellAlign: 'left',
            contain: true,
            fullscreen: true,
            pageDots: false,
            prevNextButtons: true,
            autoPlay: false
        });
    }
});