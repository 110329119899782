"use strict";

var $ = require("jquery");
import { dropdownToggle } from './_dropdownToggle.js';
import { dropdownClose } from './_dropdownClose.js';

$(document).ready(function () {

    const button = $('.filter-btn-trigger');
    button.on('click', function () {
        dropdownToggle('product-filters');
    });

    const resultsButton = $('.filter-results-btn');
    resultsButton.on('click', function () {
        dropdownClose();
        window.scrollTo(0, $(".before-shop-loop").offset().top - 100);
    });
});