"use strict";

var $ = require("jquery");

$(document).ready(function () {

    $('.btn-next').click(function () {

        // var triggerEl = document.querySelector('#myTab a[data-bs-target="#checkout-user-information"]')
        // console.log(triggerEl)
        // bootstrap.Tab.getOrCreateInstance(triggerEl).show() // Select tab by name

        // var triggerEl = document.querySelector('a[data-bs-target="#' + hash + '"]');
        // Tab.getOrCreateInstance(triggerEl).show();


        var triggerEl = $('.nav-tabs .active').next('a');
        bootstrap.Tab.getOrCreateInstance(triggerEl).show() // Select tab by name
        setTimeout(() => {
            $(window).scrollTop($("main").offset().top - 200);
        }, 100);
    })

    $('.btn-prev').click(function () {
        // $('.nav-tabs .active').prev('a').trigger('click');
        var triggerEl = $('.nav-tabs .active').prev('a');
        bootstrap.Tab.getInstance(triggerEl).show() // Select tab by name

        setTimeout(() => {
            $(window).scrollTop($("main").offset().top - 200);
        }, 100);
    })
});