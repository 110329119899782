"use strict";

var $ = require("jquery");
import { dropdownToggle } from './_dropdownToggle.js';

$(document).ready(function ($) {

    const button = $('.mini-cart-btn');
    button.on('click', function () {
        dropdownToggle('mini-cart');
    });
    const closeButton = $('.mini-cart-dropdown .btn-close');
    closeButton.on('click', function () {
        dropdownToggle('mini-cart');
    });

    jQuery(document.body).on('added_to_cart', function () {
        console.log('added to cart');
        dropdownToggle('mini-cart');
        $('#mini-cart-dropdown .alert-added').show();
        setTimeout(function () {
            $("#mini-cart-dropdown .alert-added").fadeOut();
        }, 3000);
    });

    jQuery(document.body).on('removed_from_cart', function () {
        $('#mini-cart-dropdown .alert-removed').show();
        setTimeout(function () {
            $("#mini-cart-dropdown .alert-removed").fadeOut();
        }, 3000);
    });
});