var $ = require("jquery");
import { Fancybox } from "@fancyapps/ui";

"use strict";

$(document).ready(function () {
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    if (!getCookie("popup-closed")) {
        Fancybox.bind("[data-src='#dialog-popup']", {
            click: false,
            loseClickOutside : false
        });

        if ($("#dialog-popup").length) {
            Fancybox.show([{ src: "#dialog-popup", type: "inline" }]);
        }
    }

    $("#dialog-popup .is-close, #dialog-popup .btn-popup").click(function () {
        // Create a cookie named "popup-closed" that expires in 7 days
        var date = new Date();
        date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + date.toUTCString();
        document.cookie = "popup-closed=true;" + expires + ";path=/";
    });
});